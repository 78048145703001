import services from "@/axios/dbManag";
import store from "../store/index";

const token = store.getters.tokenAdb2c
  ? store.getters.tokenAdb2c
  : localStorage.getItem("tokenAdb2c");

export const downloadElement = (item_Id, section, file_name) => {
  const url = `${services.baseUrl}/documenti/download?item_Id=${item_Id}&section=${section}&request=${token}`;
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = url;
  link.setAttribute(
    "download",
    `${item_Id}.${file_name.substring(
      file_name.lastIndexOf("."),
      file_name.lenght
    )}`
  );
  document.body.appendChild(link);
  setTimeout(() => {
    link.click();
    document.body.removeChild(link);
  }, 500);
};

export const pdfModuloPrivacy = (id_societa, id_persona, tipo) => {
  const url = `${services.baseUrl}/tesseramento/societa/tessera/richiesta?id_societa=${id_societa}&id_persona=${id_persona}&tipo=${tipo}&request=${token}`;
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = url;
  link.setAttribute("download", `modulo_consenso.pdf`);
  document.body.appendChild(link);
  setTimeout(() => {
    link.click();
    document.body.removeChild(link);
  }, 500);
};

export const pdfModuloAffiliazione = (id_societa, stagione) => {
  const url = `${services.baseUrl}/affiliazioni/societa/modulo/get?id_societa=${id_societa}&stagione=${stagione}&request=${token}`;
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = url;
  link.setAttribute(
    "download",
    `modulo_affiliazione_${id_societa}_${stagione}.pdf`
  );
  document.body.appendChild(link);
  setTimeout(() => {
    link.click();
    document.body.removeChild(link);
  }, 500);
};
export const pdfModuloAggregazione = (id_societa, stagione) => {
  console.log(id_societa, stagione);
  const url = `${services.baseUrl}/affiliazioni/enti-aggregati/modulo/get?id_societa=${id_societa}&stagione=${stagione}&request=${token}`;
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = url;
  link.setAttribute(
    "download",
    `modulo_aggregazione_${id_societa}_${stagione}.pdf`
  );
  document.body.appendChild(link);
  setTimeout(() => {
    link.click();
    document.body.removeChild(link);
  }, 500);
};

export const pdfAttestatoAffiliazione = (id_societa, stagione) => {
  const url = `${services.baseUrl}/affiliazioni/societa/attestato/get?id_societa=${id_societa}&stagione=${stagione}&request=${token}`;
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = url;
  link.setAttribute(
    "download",
    `attestato_affiliazione_${id_societa}_${stagione}.pdf`
  );
  document.body.appendChild(link);
  setTimeout(() => {
    link.click();
    document.body.removeChild(link);
  }, 500);
};

export const pdfMAV = (codice_transazione) => {
  const url = `${services.baseUrl}/pagamenti/mav/download?transaction_guid=${codice_transazione}&request=${token}`;
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = url;
  link.setAttribute("download", `MAV_${codice_transazione}.pdf`);
  document.body.appendChild(link);
  setTimeout(() => {
    link.click();
    document.body.removeChild(link);
  }, 500);
};
