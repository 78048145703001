<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="modal_download_moduli"
    data-bs-focus="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <loading
          :active="isLoading"
          :is-full-page="fullPage"
          :z-index="1100"
        ></loading>
        <div class="modal-header">
          <h5 class="modal-title">Scarica moduli società</h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body text-start">
          <div class="card-body p-6">
            <div class="ps-5 pe-5">
              <!-- <div class="row pb-4 text-hover-primary">
                <button
                  @click="stampaModuloPrivacy()"
                  class="text-start text-hover-primary text-dark"
                >
                  <i
                    class="bi bi-download text-dark fw-bold text-hover-primary"
                  >
                  </i>
                  &nbsp; Modulo consenso 2019
                </button>
              </div> -->
              <div class="row pb-4 text-hover-primary">
                <a
                  href="/media/fit-media/Documenti/2024 Designazione del Responsabile del trattamento - Affiliazione.pdf"
                  target="_blank"
                  class="text-dark text-hover-primary"
                  ><i class="bi bi-download text-dark fw-bold"> </i> &nbsp; 2024
                  Designazione del Responsabile del trattamento -
                  Affiliazione</a
                >
              </div>
              <a
                href="/media/fit-media/Documenti/2024 Designazione del Responsabile del trattamento - Affiliazione.pdf"
                target="_blank"
                class="text-dark text-hover-primary d-none"
                id="modello_organizzazione_controllo"
                >Modello di organizzazione e controllo ex D. Lgs.39/2021</a
              >
              <div class="row pb-4 text-hover-primary">
                <span
                  @click="
                    downloadModuloOrganizzazioneControllo(
                      'modello_organizzazione_controllo'
                    )
                  "
                  class="text-dark text-hover-primary"
                  style="cursor: pointer"
                  ><i class="bi bi-download text-dark fw-bold"> </i> &nbsp;
                  Modello di organizzazione e controllo ex D. Lgs.39/2021</span
                >
              </div>
              <a
                href="/media/fit-media/Documenti/2024 Designazione del Responsabile del trattamento - Affiliazione.pdf"
                target="_blank"
                class="text-dark text-hover-primary d-none"
                id="codice_condotta"
                >Codice di Condotta ex D. Lgs. 39/2021</a
              >
              <div class="row pb-4 text-hover-primary">
                <span
                  @click="
                    downloadModuloOrganizzazioneControllo('codice_condotta')
                  "
                  class="text-dark text-hover-primary"
                  style="cursor: pointer"
                  ><i class="bi bi-download text-dark fw-bold"> </i> &nbsp;
                  Codice di Condotta ex D. Lgs. 39/2021</span
                >
              </div>
              <div class="row pb-4 text-hover-primary">
                <button
                  @click="stampaModuloDomAff()"
                  class="text-start text-hover-primary text-dark"
                >
                  <i
                    class="bi bi-download text-dark fw-bold text-hover-primary"
                  >
                  </i>
                  &nbsp; Domanda di affiliazione
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            Chiudi
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch, computed, createApp } from "vue";
import { useStore } from "vuex";
import {
  pdfModuloPrivacy,
  pdfModuloAffiliazione,
} from "@/requests/downloadFiles";
import {
  modelloOrganizzazioneCreate,
  codiceCondottaCreate,
} from "@/requests/documentiSocieta";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { alertFailed, alertSuccess } from "@/composables/swAlert";
import { checkAffiliazione } from "@/requests/checkAffiliazione";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import DatepickerComponent from "./ModalDatepicker.vue";

export default defineComponent({
  name: "download-moduli-soc",
  props: ["societa", "documentoCreato"],
  components: { Loading },
  setup(props, { emit }) {
    const isLoading = ref(false);
    console.log(props.societa.id);

    const stampaModuloPrivacy = async () => {
      isLoading.value = true;
      await pdfModuloPrivacy(props.societa.id, null, "S");
      isLoading.value = false;
      // .then((res) => {
      //   isLoading.value = false;
      //   if (res.status != 200) {
      //     alertFailed(
      //       res.data.message
      //         ? res.data.message
      //         : "Attenzione! Si è verificato un errore. Riprovare più tardi"
      //     );
      //   }
      // });
    };

    const store = useStore();

    const stagioneSelected = computed(() => store.getters.stagioneSelected);

    watch(stagioneSelected);
    const objControlloDownloadDocumentoAffiliazione = ref(null);
    const societaGiaAffiliata = ref(false);

    const stampaModuloDomAff = async () => {
      societaGiaAffiliata.value = false;
      isLoading.value = true;
      await checkAffiliazione({
        anno: stagioneSelected.value,
        id_societa: props.societa.id,
      }).then((res) => {
        console.log(res);
        if (
          res.data &&
          res.data.message.includes(
            "La societ&agrave; risulta gi&agrave; affiliata o con richiesta di affiliazione per l'anno selezionato."
          )
        ) {
          societaGiaAffiliata.value = true;
        }

        objControlloDownloadDocumentoAffiliazione.value =
          res.objControlloDownloadDocumentoAffiliazione;
      });
      console.log(objControlloDownloadDocumentoAffiliazione.value);
      if (
        societaGiaAffiliata.value == true ||
        (societaGiaAffiliata.value == false &&
          !objControlloDownloadDocumentoAffiliazione.value) ||
        (objControlloDownloadDocumentoAffiliazione.value
          .affiliazionePrecedente == "OK" &&
          objControlloDownloadDocumentoAffiliazione.value.atto == "OK" &&
          objControlloDownloadDocumentoAffiliazione.value.debiti == "OK" &&
          objControlloDownloadDocumentoAffiliazione.value.consiglioDirettivo ==
            "OK" &&
          objControlloDownloadDocumentoAffiliazione.value.discipline == "OK" &&
          objControlloDownloadDocumentoAffiliazione.value.affiliazione ==
            "OK" &&
          objControlloDownloadDocumentoAffiliazione.value.impiantiCampi ==
            "OK" &&
          objControlloDownloadDocumentoAffiliazione.value.privacy == "OK" &&
          objControlloDownloadDocumentoAffiliazione.value.sanzioni == "OK" &&
          objControlloDownloadDocumentoAffiliazione.value.pickleball == "OK" &&
          objControlloDownloadDocumentoAffiliazione.value.tutela_minori == "OK")
      ) {
        await pdfModuloAffiliazione(props.societa.id, stagioneSelected.value);
        isLoading.value = false;
        return;
      } else {
        alertFailed(
          "Prima di scaricare la domanda di affiliazione è necessario completare la richiesta per l'anno corrente"
        );
        isLoading.value = false;
        return;
      }
    };
    const downloadModuloOrganizzazioneControllo = (link_to_click) => {
      Swal.fire({
        html: `Continuando il documento verrà generato ed archiviato nei documenti <br><br> <div id="datepicker-container"></div> `,
        icon: "warning",
        input: "text",
        inputAttributes: {
          id: "datePickerModuliSoc",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Conferma",
        showLoaderOnConfirm: true,
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
          cancelButton: "btn fw-bold btn-light-danger",
          input: "d-none",
          validationMessage: "mt-5",
        },
        onBeforeOpen: () => {
          const datepickerApp = createApp(DatepickerComponent);
          datepickerApp.mount("#datepicker-container");
        },
        preConfirm: async () => {
          // Puoi accedere ai dati dal componente montato se necessario
          Swal.getCancelButton().setAttribute("disabled", true);
          const date = document.querySelector("#input_modal");
          console.log(date.value);

          console.log("date", date.value);
          const dateUtc = date.value;

          const dateSelected = new Date(dateUtc);
          let resToReturn = null;
          if (dateSelected instanceof Date && !isNaN(dateSelected)) {
            switch (link_to_click) {
              case "modello_organizzazione_controllo":
                console.log(
                  "cambio parametro function per modello organizzazione controllo"
                );
                await modelloOrganizzazioneCreate(
                  props.societa.id,
                  25,
                  dateUtc
                ).then((res) => {
                  resToReturn = res.data;
                });
                break;
              case "codice_condotta":
                console.log("cambio parametro function per codice condotta");
                await codiceCondottaCreate(props.societa.id, 24, dateUtc).then(
                  (res) => {
                    resToReturn = res.data;
                  }
                );
                break;

              default:
                break;
            }
            return resToReturn;
          } else {
            alertFailed("Inserire una data valida");
            return false;
          }
        },
        inputValidator: (value) => {
          if (!value) {
            return "Inserire una data valida";
          }
        },
      }).then((res) => {
        Swal.getCancelButton().setAttribute("disabled", false);
        console.log(new Date(res.value), res);
        if (res.isDismissed) {
          return;
        } else {
          if (res.value.statusCode == 200) {
            alertSuccess("Documento generato con successo");
            emit("documentoCreato");
          } else {
            alertFailed(
              "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
            emit("documentoCreato");
          }
        }
      });
    };

    return {
      downloadModuloOrganizzazioneControllo,
      isLoading,
      stampaModuloPrivacy,
      stampaModuloDomAff,
    };
  },
});
</script>

<style scoped>
.modal-body {
  padding: 0px;
}

@media (max-width: 1200px) {
  .modal-dialog {
    max-width: 55%;
  }
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 65%;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
