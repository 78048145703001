<template>
  <component :is="tag" class="file-preview">
    <button @click="$emit('remove', file)" class="close-icon">&times;</button>
    <!-- <img
      :src="file.url"
      :alt="file.file.name"
      :title="file.file.name"
      v-if="
        file.id.toLowerCase().includes('.img') ||
        file.id.toLowerCase().includes('.jpeg')
      "
      style="width: 3rem"
    /> -->
    <img
      src="/media/fit-media/png_icon.png"
      :alt="file.file.name"
      :title="file.file.name"
      v-if="file.id.toLowerCase().includes('.png')"
      style="width: 3rem"
    />
    <img
      src="/media/fit-media/PDF.png"
      :alt="file.file.name"
      :title="file.file.name"
      v-else-if="file.id.toLowerCase().includes('.pdf')"
      style="width: 3rem"
    />
    <img
      src="/media/fit-media/DOC.png"
      :alt="file.file.name"
      :title="file.file.name"
      v-else-if="
        file.id.toLowerCase().includes('.doc') ||
        file.id.toLowerCase().includes('.docx')
      "
      style="width: 3rem"
    />
    <img
      src="/media/fit-media/pptx_icon.svg"
      :alt="file.file.name"
      :title="file.file.name"
      v-else-if="
        file.id.toLowerCase().includes('.ppt') ||
        file.id.toLowerCase().includes('.pptx')
      "
      style="width: 3rem"
    />
    <img
      src="/media/fit-media/icona_xls.svg"
      :alt="file.file.name"
      :title="file.file.name"
      v-else-if="
        file.id.toLowerCase().includes('.xls') ||
        file.id.toLowerCase().includes('.xlsx')
      "
      style="width: 3rem"
    />
    <img
      src="/media/fit-media/jpg_icon.svg"
      :alt="file.file.name"
      :title="file.file.name"
      v-else-if="
        file.id.toLowerCase().includes('.jpg') ||
        file.id.toLowerCase().includes('.jpeg')
      "
      style="width: 3rem"
    />
    <!-- <span class="status-indicator loading-indicator">{{ file.file.name }}</span> -->

    <h1 v-show="file.status == 'loading'" class="me-2 badge badge-primary">
      Uploading...
    </h1>
    <h1 v-show="file.status == false" class="me-2 badge badge-light-danger">
      Errore
    </h1>
    <h1 v-show="file.status == true" class="me-2 badge badge-light-success">
      Upload Completato
    </h1>
    <!-- <span
      class="status-indicator loading-indicator"
      v-show="file.status == 'loading'"
      >In Progress</span
    >
    <span
      class="status-indicator success-indicator"
      v-show="file.status == true"
      >Uploaded</span
    >
    <span
      class="status-indicator failure-indicator"
      v-show="file.status == false"
      >Error</span
    > -->
  </component>
</template>

<script>
export default {
  name: "file-preview",
  emits: ["remove"],
  props: {
    file: { type: Object, required: true },
    tag: { type: String, default: "li" },
  },
};
</script>

<style scoped>
.file-preview {
  list-style-type: none;
}
/* .file-preview {
	width: 20%;
	margin: 1rem 2.5%;
	position: relative;
	aspect-ratio: 1/1;
	overflow: hidden;
	img {
		width: 100%;
		height: 100%;
		display: block;
		object-fit: cover;
	}
	.close-icon, .status-indicator {
		--size: 20px;
		position: absolute;
		line-height: var(--size);
		height: var(--size);
		border-radius: var(--size);
		box-shadow: 0 0 5px currentColor;
		right: 0.25rem;
		appearance: none;
		border: 0;
		padding: 0;
	}
	.close-icon {
		width: var(--size);
		font-size: var(--size);
		background: #933;
		color: #fff;
		top: 0.25rem;
		cursor: pointer;
	}
	.status-indicator {
		font-size: calc(0.75 * var(--size));
		bottom: 0.25rem;
		padding: 0 10px;
	}
	.loading-indicator {
		animation: pulse 1.5s linear 0s infinite;
		color: #000;
	}
	.success-indicator {
		background: #6c6;
		color: #040;
	}
	.failure-indicator {
		background: #933;
		color: #fff;
	}
}
@keyframes pulse {
	0% {
		background: #fff;
	}
	50% {
		background: #ddd;
	}
	100% {
		background: #fff;
	}
} */
</style>
