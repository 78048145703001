<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <!-- m-5 pb-2 -->
    <div class="text-start">
      <div class="row mt-5 justify-content-between">
        <div
          class="col-md-4"
          v-if="!readOnly && puo_utilizzare_pulsanti_sezioni"
        >
          <div>
            <button
              type="button"
              class="btn btn-light text-gray-700 fs-6 text-start"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_add-doc-societa"
            >
              <i class="bi bi-plus fs-6 text-gray-700"></i>aggiungi documento
            </button>
          </div>
        </div>
        <div class="col-md-4">
          <div
            :class="
              !readOnly && puo_utilizzare_pulsanti_sezioni ? 'text-center' : ''
            "
          >
            <button
              type="button"
              class="btn btn-light text-gray-700 fs-6"
              data-bs-toggle="modal"
              data-bs-target="#modal_download_moduli"
            >
              <i class="bi bi-download fs-6 text-gray-700"></i>scarica moduli
            </button>
          </div>
        </div>
        <div class="col-md-4">
          <div class="text-end mt-4" style="margin-right: 19px">
            Documenti:
            <b>{{
              documenti_societa_list ? documenti_societa_list.length : 0
            }}</b>
          </div>
        </div>
      </div>
      <br />
      <div class="mx-auto my-auto text-center" v-if="!loaded">
        <div class="spinner-border text-gray-600" role="status"></div>
        <span class="text-gray-600 ps-3 fs-2">Loading</span>
      </div>
      <div
        v-else-if="
          documenti_societa_list.length === 0 &&
          loaded &&
          (status == 200 || status == 204)
        "
        class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-t5 pb-5"
      >
        <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
        <span class="text-gray-700 ps-3 fs-2">
          Attenzione! Nessun risultato.
        </span>
        <span
          type="button"
          data-bs-toggle=""
          data-bs-target=""
          aria-expanded="false"
          aria-controls=""
          class="text-gray-600 pt-4"
          @click.prevent="$emit('resetFilters')"
        >
          <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
        ></span>
      </div>
      <div
        v-else-if="status != 200 && status != 204"
        class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-t5 pb-5"
      >
        <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
        <span class="text-gray-700 ps-3 fs-2">
          Attenzione! Si è verificato un errore. Riprovare più tardi.
        </span>
        <span
          type="button"
          data-bs-toggle=""
          data-bs-target=""
          aria-expanded="false"
          aria-controls=""
          class="text-gray-600 pt-4"
        >
          <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
            <i class="bi bi-house fs-2 text-gray-800"> </i
          ></router-link>
          <router-link v-else to="/bacheca-public">
            <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
        ></span>
      </div>
      <div v-else>
        <Datatable
          :table-header="tableHeader"
          :table-data="documenti_societa_list"
          :total="record"
          :current-page="currentPage"
          :rows-per-page="fetchRows"
          :sortLabel="sortColumn"
          :order="sortOrder"
          :enableItemsPerPageDropdown="record > 10"
          @sort="setSortOrderColumn"
          @items-per-page-change="setFetchRowsDocSoc"
          @current-change="setCurrentPageDocSoc"
        >
          <template v-slot:cell-file_name="{ row: data }">
            <div
              class="text-center"
              @click="downloadDocument(data.item_id, data.estensione_file)"
            >
              <PreviewDaEstensione :estensione="data.estensione_file" />
            </div>
          </template>
          <template v-slot:cell-tipo="{ row: data }">
            {{ data.tipo }}
          </template>
          <template v-slot:cell-ogg="{ row: data }">
            <div class="tab-long">
              {{ data.ogg }}
            </div>
          </template>
          <template v-slot:cell-descr="{ row: data }">
            <div class="tab-long">
              {{ data.descr }}
            </div>
          </template>
          <template v-slot:cell-data="{ row: data }">
            <div class="text-center">
              {{ data.data }}
            </div>
          </template>
          <template v-slot:cell-options="{ row: data }">
            <div class="btn-group" @click="selectedDocId = data.item_id">
              <button
                class="btn btn-sm dropdown"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
              </button>
              <ul class="dropdown-menu">
                <a
                  href="#"
                  @click="downloadDocument(data.item_id, data.estensione_file)"
                >
                  <li role="presentation" class="navi-item">
                    <div class="b-dropdown-text text-start py-2 px-4">
                      <div class="navi-link">
                        <i class="bi bi-download text-dark fs-5"></i>
                        <span class="text-dark ps-1 fs-7" type="button">
                          Download
                        </span>
                      </div>
                    </div>
                  </li></a
                >
                <a
                  href="#"
                  v-if="!readOnly"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_doc-societa"
                  @click="disabled = true"
                >
                  <li role="presentation" class="navi-item">
                    <div class="b-dropdown-text text-start py-2 px-4">
                      <div class="navi-link">
                        <i class="bi bi-eye text-dark fs-5"></i>
                        <span class="text-dark ps-1 fs-7" type="button">
                          Visualizza
                        </span>
                      </div>
                    </div>
                  </li></a
                >
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_doc-societa"
                  @click="disabled = false"
                  v-if="!readOnly && puo_utilizzare_pulsanti_sezioni"
                >
                  <li role="presentation" class="navi-item">
                    <div class="b-dropdown-text text-start py-2 px-4">
                      <div class="navi-link">
                        <i class="bi bi-pen text-dark fs-5"></i>
                        <span class="text-dark ps-1 fs-7"> Modifica</span>
                      </div>
                    </div>
                  </li></a
                >
                <a
                  href="#"
                  @click="eliminaFile(data.item_id)"
                  v-if="!readOnly && puo_utilizzare_pulsanti_sezioni"
                >
                  <li role="presentation" class="navi-item">
                    <div class="b-dropdown-text text-start py-2 px-4">
                      <div class="navi-link">
                        <i class="bi bi-trash text-dark fs-5"></i>
                        <span class="text-dark ps-1 fs-7"> Elimina </span>
                      </div>
                    </div>
                  </li></a
                >
              </ul>
            </div>
          </template>
        </Datatable>
      </div>
      <br />
    </div>
  </div>
  <AddDocSocieta
    :societa="societa"
    @documentAdded="$emit('getDocumentiSocieta')"
  />
  <DocSocietaDetail
    :societa="societa"
    :selectedId="selectedDocId"
    @eliminaDocumento="eliminaFile(data.id_documento)"
    @documentEdited="
      $emit('getDocumentiSocieta');
      selectedDocId = null;
    "
    @resetIdDoc="selectedDocId = null"
    :disabled="disabled"
  />
  <DownloadModuliSoc
    :societa="societa"
    @documentoCreato="$emit('getDocumentiSocieta')"
  />
</template>
<script>
import { defineComponent, ref, computed } from "vue";
import DocSocietaDetail from "@/components/components-fit/società/documenti/DocSocietaDetail.vue";
import AddDocSocieta from "@/components/components-fit/società/documenti/AddDocSocieta.vue";
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import DownloadModuliSoc from "@/components/components-fit/società/documenti/DownloadModuliSoc.vue";
import { useStore } from "vuex";
import { alertSuccess, alertFailed } from "@/composables/swAlert";
import { deleteDocumento } from "@/requests/documentiSocieta";
import { downloadElement } from "@/requests/downloadFiles";
import PreviewDaEstensione from "@/components/components-fit/utility/PreviewDaEstensione.vue";
import isEnabled from "@/composables/isEnabled.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default defineComponent({
  name: "table-doc-soc",
  emits: ["getDocumentiSocieta", "resetFilters"],
  props: {
    societa: {
      type: Object,
      required: false,
    },
  },
  components: {
    DocSocietaDetail,
    AddDocSocieta,
    Datatable,
    DownloadModuliSoc,
    Loading,
    PreviewDaEstensione,
  },
  setup(props, { emit }) {
    const store = useStore();

    const setFetchRowsDocSoc = (e) => {
      store.commit("setFetchRowsDocSoc", e);
      emit("getDocumentiSocieta");
    };
    const setCurrentPageDocSoc = (page) => {
      store.commit("setCurrentPageDocSoc", page);
      emit("getDocumentiSocieta");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnDocSoc", columnName);
      store.commit("setSortOrderDocSoc", order);
      emit("getDocumentiSocieta");
    };

    const tableHeader = ref([
      {
        key: "file_name",
        sortable: false,
      },
      {
        name: "Tipo",
        key: "tipo",
        sortable: false,
      },
      {
        name: "Oggetto",
        key: "ogg",
      },
      {
        name: "Descrizione",
        key: "descr",
      },
      {
        name: "Data",
        key: "data",
        align: "center",
      },
      {
        key: "options",
        sortable: false,
      },
    ]);
    const downloadingFile = ref(false);
    const downloadDocument = async (id, estensione) => {
      if (!downloadingFile.value) {
        downloadingFile.value = true;
        await downloadElement(id, "societa", estensione);
        // .then((res) => {
        //   if (res.status != 200) {
        //     alertFailed(
        //       res.data.message
        //         ? res.data.message
        //         : "Attenzione! Si è verificato un errore. Riprovare più tardi"
        //     );
        //   }
        // });
        downloadingFile.value = false;
      }
    };

    const isLoading = ref(false);

    const eliminaFile = (idDoc) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Una volta cancellato, non portai piu recuperare il file!",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Elimina",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        console.log(e);
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await deleteDocumento(idDoc).then((res) => {
            isLoading.value = false;
            if (res.status == 200) {
              alertSuccess("File eliminato");
              emit("getDocumentiSocieta");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
          });
        }
      });
    };

    const selectedDocId = ref("");
    const disabled = ref(false);

    return {
      documenti_societa_list: computed(() =>
        store.getters.getStateFromName("resultsdocumenti_societa_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadeddocumenti_societa_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recorddocumenti_societa_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusdocumenti_societa_list")
      ),
      currentPage: computed(() => store.getters.currentPageDocSoc),
      rowsToSkip: computed(() => store.getters.rowsToSkipDocSoc),
      fetchRows: computed(() => store.getters.fetchRowsDocSoc),
      sortColumn: computed(() => store.getters.sortColumnDocSoc),
      sortOrder: computed(() => store.getters.sortOrderDocSoc),
      setFetchRowsDocSoc,
      setCurrentPageDocSoc,
      setSortOrderColumn,
      tableHeader,
      eliminaFile,
      selectedDocId,
      downloadDocument,
      downloadingFile,
      disabled,
      isLoading,
      isEnabled,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      puo_utilizzare_pulsanti_sezioni: computed(
        () => store.getters.puo_utilizzare_pulsanti_sezioni
      ),
    };
  },
});
</script>
<style scoped>
/* .dropdown-menu {
  position: fixed !important;
} */
</style>
